import { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "app/auth/axios-interceptor";
import dayjs from "dayjs";

interface Props {
  date: dayjs.Dayjs;
}

interface PointStat {
  user: string;
  totalPoints: number;
}

const columns = [
  {
    title: "Użytkownik",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "Punkty",
    dataIndex: "totalPoints",
    key: "totalPoints",
    render: (text: number) => text.toFixed(2),
  },
];

export default function PointStatTable({ date }: Props) {

  const [stats, setStats] = useState<PointStat[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const reqParams = {
      date: date.format("YYYY-MM-DD"),
    };

    axios.get<PointStat[]>(`/api/stats/points`, { params: reqParams })
      .then((response) => setStats(response.data))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [date]);

  return (
    <Table<PointStat>
      rowKey={(record) => record.user}
      dataSource={stats}
      columns={columns}
      loading={loading}
      pagination={false}
    />
  );
}
