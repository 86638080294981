import { Card, Col, Descriptions, Row } from "antd";
import type { DescriptionsProps } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import OrderStatusTag from "app/orders/order-status-tag";
import OrderDetails from "./OrderDetails";
import OrderRealizeDate from "./order-realize-date";
import { ORDER_TYPE_NAMES } from "app/orders/OrderType";

export default function OrderDetailsCard({
  id,
  details,
  onChange,
}: {
  id: string;
  details: OrderDetails;
  onChange: () => void;
}) {
  const submitItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Numer",
      children: details.number,
    },
    {
      key: "2",
      label: "Data wystawienia",
      children: dayjs
        .utc(details.creationDate)
        .local()
        .format("DD.MM.YYYY HH:mm"),
    },
    {
      key: "3",
      label: "Wystawiający",
      children: details.issuer,
    },
  ];

  const recipientItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Typ",
      children: ORDER_TYPE_NAMES.get(details.recipient.type),
    },
    {
      key: "2",
      label: "Klient",
      children: details.recipient.customer.name,
    },
    {
      key: "3",
      label: "Podzlecenie",
      children: details.recipient.subcontract,
    },
  ];

  const realizeItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Status",
      children: <OrderStatusTag status={details.status} />,
    },
    {
      key: "2",
      label: "Data realizacji",
      children: (
        <OrderRealizeDate
          orderId={id}
          realizeDate={details.realizeDate}
          onChange={onChange}
        />
      ),
    },
  ];

  return (
    <Card title="Szczegóły" styles={{ header: { backgroundColor: "#e6f7ff" } }}>
      <Row gutter={32}>
        <Col xs={24} sm={8}>
          <Descriptions title="Wystawienie" items={submitItems} column={1} />
        </Col>
        <Col xs={24} sm={8}>
          <Descriptions
            title="Zamawiający"
            items={recipientItems}
            column={1}
            extra={
              <Authorized roles={[...Roles.orderEdit, ...Roles.orderCreate]}>
                <Link to={`/zamowienia/${id}/zamawiajacy`}>Edytuj</Link>
              </Authorized>
            }
          />
        </Col>
        <Col xs={24} sm={8}>
          <Descriptions title="Realizacja" items={realizeItems} column={1} />
        </Col>
      </Row>
    </Card>
  );
}
