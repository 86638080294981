import { useNavigate } from "react-router-dom";
import axios from "app/auth/axios-interceptor";
import OrderRecipientForm from "app/orders/order/recipient/order-recipient-form";
import OrderRecipient from "./recipient/OrderRecipient";
import { useAuth } from "app/auth/auth-provider";
import { useEffect, useState } from "react";

interface OrderCreated {
  id: string;
}

export default function OrderAddPage() {
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState<OrderRecipient>();
  const auth = useAuth();

  useEffect(() => {
    console.log(auth);
    if (auth.customerId) {
      const wholesaler: OrderRecipient = {
        type: "WHOLESALE",
        customer: {
          id: auth.customerId,
          name: auth.fullName,
          type: "WHOLESALER",
        },
      };
      setRecipient(wholesaler);
    }
  }, [auth]);

  function onFinish(values: OrderRecipient) {
    axios
      .post<OrderCreated>("/api/orders", values)
      .then((response) => {
        navigate(`/zamowienia/${response.data.id}`);
      })
      .catch(console.error);
  }

  return (
    <OrderRecipientForm
      recipient={recipient}
      onFinish={(values) => onFinish(values)}
    />
  );
}
