import { Outlet, redirect } from "react-router-dom";
import "dayjs/locale/pl";
import axios from "app/auth/axios-interceptor";

import AppLayout from "app/app-layout";
import { useAuth } from "app/auth/auth-provider";
import { Roles } from "app/auth/roles";
import HomePage from "app/home/home-page";
import CustomersPage from "app/customers/customers-page";
import CustomerAddPage from "app/customers/customer-add-page";
import CustomerEditPage from "app/customers/customer-edit-page";
import Customer from "app/customers/Customer";
import OrdersPage from "app/orders/orders-page";
import OrderPage from "app/orders/order/order-page";
import OrderAddPage from "app/orders/order/order-add-page";
import OrderRecipientEditPage from "app/orders/order/recipient/order-recipient-edit-page";
import OrderItemAddPage from "app/orders/order/items/order-item-add-page";
import OrderCommentEditPage from "app/orders/order/comments/order-comment-edit-page";
import OrderProductStagePage from "app/orders/order/stages/order-product-stage-page";
import OrderLayoutPage from "app/orders/order/order-layout-page";
import OrderCommentAddPage from "app/orders/order/comments/order-comment-add-page";
import OrderItemEditPage from "app/orders/order/items/order-item-edit-page";
import Order from "app/orders/Order";
import ProductsPage from "app/products/products-page";
import ProductDetails from "app/products/product-page";
import Product from "app/products/Product";
import { PRODUCT_FAMILY_NAMES } from "app/products/ProductFamily";
import StatsPage from "app/stats/stats-page";
import FabricsPage from "app/fabrics/fabrics-page";
import FabricAddPage from "app/fabrics/fabric-add-page";
import FabricEditPage from "app/fabrics/fabric-edit-page";
import Fabric from "app/fabrics/Fabric";
import UsersPage from "app/users/users-page";
import OrderDetails from "./orders/order/recipient/OrderDetails";
import UserEditPage from "./users/user-edit-page";
import User, { getFullName } from "./users/User";
import UserAddPage from "./users/user-add-page";

async function getCustomer(customerId: string) {
  const url = `/api/customers/${customerId}`;
  return axios.get<{ data: Customer }>(url).then((response) => response.data);
}

async function getProduct(productId: string) {
  const url = `/api/products/${productId}`;
  return axios.get<{ data: Product }>(url).then((response) => response.data);
}

async function getFabric(fabricId: string) {
  const url = `/api/fabrics/${fabricId}`;
  return axios.get<{ data: Fabric }>(url).then((response) => response.data);
}

async function getUser(username: string) {
  const url = `/api/users/${username}`;
  return axios.get<{ user: User }>(url).then((response) => response.data);
}

async function getOrder(orderId: string) {
  const url = `/api/orders/${orderId}/info`;
  return axios.get<{ data: Order }>(url).then((response) => response.data);
}

const routes = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "zamowienia",
        element: (
          <RequireAuth roles={Roles.orderView}>
            <Outlet />
          </RequireAuth>
        ),
        handle: {
          crumb: () => "Zamówienia",
        },
        children: [
          {
            index: true,
            element: <OrdersPage />,
          },
          {
            path: "dodaj",
            element: <OrderAddPage />,
            handle: {
              crumb: () => "Nowe Zamówienie",
            },
          },
          {
            path: ":orderId",
            element: <OrderLayoutPage />,
            loader: async ({ params }) => getOrder(params.orderId),
            handle: {
              crumb: (details: OrderDetails) => details.number,
            },
            children: [
              {
                index: true,
                element: <OrderPage />,
              },
              {
                path: "zamawiajacy",
                element: <OrderRecipientEditPage />,
                handle: {
                  crumb: () => `Zamawiający`,
                },
              },
              {
                path: "produkty/dodaj",
                element: <OrderItemAddPage />,
                handle: {
                  crumb: () => `Produkt`,
                },
              },
              {
                path: "produkty/:itemId",
                element: <OrderItemEditPage />,
                handle: {
                  crumb: () => `Produkt`,
                },
              },
              {
                path: "produkty/:productId/etapy/:stageId",
                element: <OrderProductStagePage />,
                loader: ({ params }) => params.stageId,
                handle: {
                  crumb: (stage: string) => stage,
                },
              },
              {
                // redirect previous QR code link
                path: "etapy",
                loader: ({ request, params }) => {
                  const url = new URL(request.url);
                  const product = url.searchParams.get("produkt");
                  return redirect(
                    `/zamowienia/${params.orderId}?produkt=${product}`
                  );
                },
              },
              {
                path: "komentarze/dodaj",
                element: <OrderCommentAddPage />,
                handle: {
                  crumb: () => `Komentarz`,
                },
              },
              {
                path: "komentarze/:commentId",
                element: <OrderCommentEditPage />,
                handle: {
                  crumb: () => `Komentarz`,
                },
              },
            ],
          },
        ],
      },
      {
        path: "klienci",
        element: (
          <RequireAuth roles={Roles.customerView}>
            <Outlet />
          </RequireAuth>
        ),
        handle: {
          crumb: () => "Klienci",
        },
        children: [
          {
            index: true,
            element: <CustomersPage />,
          },
          {
            path: "dodaj",
            element: <CustomerAddPage />,
            handle: {
              crumb: () => "Nowy Klient",
            },
          },
          {
            path: ":customerId",
            element: <CustomerEditPage />,
            loader: async ({ params }) => getCustomer(params.customerId),
            handle: {
              crumb: (customer: Customer) => customer.name,
            },
          },
        ],
      },
      {
        path: "produkty",
        element: (
          <RequireAuth roles={Roles.productView}>
            <Outlet />
          </RequireAuth>
        ),
        handle: {
          crumb: () => "Produkty",
        },
        children: [
          {
            index: true,
            element: <ProductsPage />,
          },
          {
            path: ":productId",
            element: <ProductDetails />,
            loader: async ({ params }) => getProduct(params.productId),
            handle: {
              crumb: (product: Product) =>
                `${PRODUCT_FAMILY_NAMES.get(product.family)} - ${product.title}`,
            },
          },
        ],
      },
      {
        path: "tkaniny",
        element: (
          <RequireAuth roles={Roles.fabricView}>
            <Outlet />
          </RequireAuth>
        ),
        handle: {
          crumb: () => "Tkaniny",
        },
        children: [
          {
            index: true,
            element: <FabricsPage />,
          },
          {
            path: "dodaj",
            element: <FabricAddPage />,
            handle: {
              crumb: () => "Nowa Tkanina",
            },
          },
          {
            path: ":fabricId",
            element: <FabricEditPage />,
            loader: async ({ params }) => getFabric(params.fabricId),
            handle: {
              crumb: (fabric: Fabric) => fabric.name,
            },
          },
        ],
      },
      {
        path: "statystyki",
        element: (
          <RequireAuth roles={Roles.statView}>
            <StatsPage />
          </RequireAuth>
        ),
        handle: {
          crumb: () => "Statystyki",
        },
      },
      {
        path: "uzytkownicy",
        element: (
          <RequireAuth roles={Roles.userView}>
            <Outlet />
          </RequireAuth>
        ),
        handle: {
          crumb: () => "Użytkownicy",
        },
        children: [
          {
            index: true,
            element: <UsersPage />,
          },
          {
            path: "dodaj",
            element: <UserAddPage />,
            handle: {
              crumb: () => "Nowy Użytkownik",
            },
          },
          {
            path: ":username",
            element: <UserEditPage />,
            loader: async ({ params }) => getUser(params.username),
            handle: {
              crumb: (user: User) => getFullName(user),
            },
          },
        ],
      },
    ],
  },
];

function RequireAuth({
  children,
  roles = [],
}: {
  children: React.ReactNode;
  roles: string[];
}) {
  const auth = useAuth();
  if (!auth.hasAnyRole(roles)) {
    return null;
  }

  return children;
}

export default routes;
