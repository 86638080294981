import { Table } from "antd";
import { Link } from "react-router-dom";
import SearchDropdown from "app/common/search-dropdown";
import Fabric from "app/fabrics/Fabric";
import type { ColumnsType } from "antd/es/table";
import SelectDropdown from "app/common/select-dropdown";
import { useEffect, useState } from "react";

export default function FabricsTable({
  fabrics,
  loading,
  onSelect,
}: {
  fabrics: Fabric[];
  loading: boolean;
  onSelect: (ids: string[]) => void;
}) {
  const [categories, setCategories] = useState<string[]>([]);

  const columns: ColumnsType<Fabric> = [
    {
      title: "Kategoria",
      dataIndex: "category",
      key: "category",
      filterDropdown: (props) => (
        <SelectDropdown values={categories} {...props} />
      ),
      onFilter: (value, record) =>
        typeof value === "string" &&
        record.category.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.category.localeCompare(b.category),
      defaultSortOrder: "ascend",
    },
    {
      title: "Tkanina",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/tkaniny/${record.id}`}>{text}</Link>
      ),
      filterDropdown: (props) => <SearchDropdown {...props} />,
      onFilter: (value, record) =>
        typeof value === "string" &&
        record.name.toString().toLowerCase().includes(value.toLowerCase()),
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: "ascend",
    },
    {
      title: "Kupon",
      dataIndex: "coupon",
      key: "coupon",
    },
    {
      title: "Wymiar",
      key: "dimension",
      render: (_, record) => `${record.width} x ${record.height}`,
    },
    {
      title: "Rewir",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Uwagi",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  useEffect(() => {
    if (fabrics) {
      setCategories(Array.from(new Set(fabrics.map((f) => f.category))));
    }
  }, [fabrics]);

  return (
    <Table<Fabric>
      rowKey={(record) => record.id}
      rowSelection={{
        onChange: (selectedRowKeys) => {
          onSelect(selectedRowKeys.map((v) => v.toString()));
        },
      }}
      columns={columns}
      loading={loading}
      dataSource={fabrics}
    />
  );
}
