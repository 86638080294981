import { Row, Col, Space, Typography } from "antd";
import { useEffect, useState } from "react";

import axios from "app/auth/axios-interceptor";
import { useAuth } from "app/auth/auth-provider";

const { Title, Text } = Typography;

export default function HomePage() {
  const auth = useAuth();
  if (!auth.username) {
    return null;
  }

  const [points, setPoints] = useState<number>(0);

  useEffect(() => {
    axios
      .get<number>(`/api/stats/points/${auth.username}`)
      .then((response) => {
        setPoints(response.data);
      })
      .catch((error) => console.log(error));
  }, [auth]);

  function hasPoints() {
    return points > 0;
  }

  return (
    <Row justify="center" style={{ padding: 20 }}>
      <Col>
        <Space direction="vertical" style={{ textAlign: "center" }}>
          <>
            <Title>Witaj{`, ${auth.fullName}!`}</Title>
            {hasPoints() && (
              <Space direction="vertical">
                <hr />
                <Text>W tym miesiącu posiadasz:</Text>
                <Title level={2} type="success">
                  {points} punktów
                </Title>
              </Space>
            )}
          </>
        </Space>
      </Col>
    </Row>
  );
}
