import { useEffect, useState } from "react";
import { Form, Input, Space, InputNumber, Select } from "antd";
import axios from "app/auth/axios-interceptor";
import Authorized from "app/auth/authorized";
import { Roles } from "app/auth/roles";
import SaveButton from "app/common/save-button";
import DeleteButton from "app/common/delete-button";
import Fabric from "app/fabrics/Fabric";

interface Props {
  value?: Fabric;
  onSave: (fabric: Fabric) => void;
  onDelete?: () => void;
}

export default function FabricForm({ value, onSave, onDelete }: Props) {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    if (value) {
      form.setFieldsValue(value);
    }
  }, [value]);

  function loadCategories() {
    axios
      .get<string[]>("/api/fabric-categories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch(console.error);
  }

  useEffect(() => {
    loadCategories();
  }, []);

  const categoryOptions = categories.map((c) => ({ value: c, label: c }));

  return (
    <Form<Fabric>
      name="fabric"
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      initialValues={{ remember: true }}
      onFinish={onSave}
    >
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item
        label="Kategoria"
        name="category"
        rules={[{ required: true, message: "Wymagane pole" }]}
      >
        <Select options={categoryOptions} />
      </Form.Item>
      <Form.Item
        label="Nazwa"
        name="name"
        rules={[
          { required: true, message: "Wymagane pole" },
          { min: 3, message: "Minimum 3 znaki" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Kupon" name="coupon">
        <Input />
      </Form.Item>
      <Form.Item
        label="Szerokość"
        name="width"
        rules={[{ required: true, message: "Wymagane pole" }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        label="Wysokość"
        name="height"
        rules={[{ required: true, message: "Wymagane pole" }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item label="Rewir" name="group">
        <Input />
      </Form.Item>
      <Form.Item label="Uwagi" name="remarks">
        <Input />
      </Form.Item>
      <Authorized roles={Roles.fabricEdit}>
        <Form.Item wrapperCol={{ sm: { offset: 4, span: 20 } }}>
          <Space>
            <SaveButton onClick={form.submit} />
            {onDelete && (
              <DeleteButton
                hint="Czy na pewno usunąć tkaninę?"
                onConfirm={onDelete}
              />
            )}
          </Space>
        </Form.Item>
      </Authorized>
    </Form>
  );
}
