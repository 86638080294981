import { useEffect, useState } from "react";
import { AutoComplete, Typography, Space, Modal } from "antd";
import axios from "app/auth/axios-interceptor";

import CustomerForm from "app/customers/customer-form";
import PlusButton from "app/common/plus-button";
import Customer from "app/customers/Customer";
import CustomerPreview from "./CustomerPreview";
import Authorized from "app/auth/authorized";
import Roles from "app/auth/roles";

const { Option } = AutoComplete;
const { Text } = Typography;

interface Props {
  value?: CustomerPreview;
  onSelect: (customer?: CustomerPreview) => void;
  disabled?: boolean;
}

function CustomerField({ value, onSelect, disabled }: Props) {
  const [text, setText] = useState<string>("");
  const [customers, setCustomers] = useState<CustomerPreview[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  function onCustomerSearch(searchText: string) {
    const url = `/api/customers/findByText`;
    const requestParams = {
      text: searchText,
    };

    axios
      .get<CustomerPreview[]>(url, { params: requestParams })
      .then((response) => {
        setCustomers(response.data);
      })
      .catch(console.error);
  }

  function onCustomerSelect(option) {
    const customer = customers.find((c) => c.id === option.key);
    onSelect(customer);
  }

  function onCustomerAdd(customer: Customer) {
    onSelect(customer);
    setVisible(false);
  }

  function openCustomerDialog() {
    setVisible(true);
  }

  function onTextChange(data: string) {
    setText(data);
  }

  useEffect(() => {
    setText(value?.name || "");
  }, [value]);

  return (
    <>
      <Space>
        <AutoComplete
          style={{ width: 200 }}
          onChange={(currentText) => onTextChange(currentText)}
          onSearch={(currentText) => onCustomerSearch(currentText)}
          onSelect={(_, currentOption) => onCustomerSelect(currentOption)}
          value={text}
          disabled={disabled}
        >
          {customers.map((customer: CustomerPreview) => (
            <Option key={customer.id} value={customer.name}>
              <Space direction="vertical">
                <Text>{customer.name}</Text>
                <Text type="secondary">
                  {customer.address || "(brak adresu)"}
                </Text>
              </Space>
            </Option>
          ))}
        </AutoComplete>
        <Authorized roles={Roles.orderEdit}>
          <PlusButton onClick={() => openCustomerDialog()} />
        </Authorized>
      </Space>
      <Modal
        title="Nowy klient"
        width={800}
        open={visible}
        footer={[]}
        onCancel={() => setVisible(false)}
      >
        <CustomerForm
          onSave={(currentCustomer) => onCustomerAdd(currentCustomer)}
        />
      </Modal>
    </>
  );
}

export default CustomerField;
