import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Space } from "antd";
import axios from "app/auth/axios-interceptor";

import CustomerForm from "app/customers/customer-form";
import CustomerOrders from "app/customers/customer-orders";
import Customer from "./Customer";
import Order from "app/orders/Order";
import { Link } from "react-router-dom";

export default function CustomerEditPage() {

  const { customerId } = useParams();
  const navigate = useNavigate();

  const [customer, setCustomer] = useState<Customer>();
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    axios.get<Customer>(`/api/customers/${customerId}`)
      .then((response) => {
        setCustomer(response.data);
      })
      .catch(console.error);
  }, [customerId]);

  useEffect(() => {
    if (customer) {
      const requestParams = { customerId };
      axios.get<Order[]>(`/api/customers/findOrders`, { params: requestParams })
        .then((response) => {
          setOrders(response.data);
        })
        .catch(console.error);
    }
  }, [customer]);

  function onSaveCustomer(newCustomer: Customer) {
    axios.put(`/api/customers/${newCustomer.id}`, newCustomer)
      .then(() => {
        navigate("/klienci");
      })
      .catch(console.error);
  }

  function onDeleteCustomer() {
    axios.delete(`/api/customers/${customerId}`)
      .then(() => {
        navigate("/klienci");
      })
      .catch(console.error);
  }

  return (
    <Row gutter={16}>
      <Col span={24} sm={12}>
        <Space direction="vertical" style={{width: "100%"}}>
        <CustomerForm
          value={customer}
          onSave={(currentCustomer) => onSaveCustomer(currentCustomer)}
          canDelete={!orders.length}
          onDelete={() => onDeleteCustomer()}
        />
        <Link to={`/uzytkownicy/dodaj?customerId=${customerId}`}>Utwórz użytkownika</Link>
        </Space>
      </Col>
      <Col span={24} sm={12}>
        <CustomerOrders orders={orders} />
      </Col>
    </Row>
  );
}
